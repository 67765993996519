import { ZapparCanvas } from '@zappar/zappar-react-three-fiber';
import { Suspense, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import * as THREE from 'three';
import './App.scss';
import { Toolbar } from "./components/camera-ui-screen/camera-ui-screen";
import { BrowserCompatibility, ImageTracker, ZapparCamera, ZapparCameraHandles } from "./libs/zappar-react-three-fiber";

import { RootState } from '@react-three/fiber';
import { RecordButton } from './components/camera-ui-screen/record-button';
import { MediaSaveOverlay, MediaSaveOverlayProps } from './components/media-save-overlay/media-save-overlay';
import { MasuMediaRecorder } from './libs/media-recorder';
import { HttpUrls } from './shared/constants/http-urls';

function App() {
    // Set up states
    const [visibleState, setVisibleState] = useState(false);
    const [renderer, setRenderer] = useState<THREE.WebGLRenderer>(null);
    const [scene, setScene] = useState<THREE.Scene>(null);
    const [currentCamera, setCurrentCamera] = useState(false); // True for front camera, false for back camera;
    const [mediaOverlayVisible, setMediaOverlayVisible] = useState(false);
    const [mediaOverlayData, setMediaOverlayData] = useState<MediaSaveOverlayProps>(null);
    const [trackers, setTrackers] = useState<any[]>([]);
    
    const trackerContent = useRef<any>();
    const zapperCamera = useRef<ZapparCameraHandles>();
    const mediaRecorder = useRef<MasuMediaRecorder>(null);

    const toggleVideo = (visible: boolean) => {
        if (trackerContent.current) {
            if (visible) {
                trackerContent.current.playVideo();
            } else {
                trackerContent.current.pauseVideo();
            }
        }
    }

    const toggleCamera = () => {
        setCurrentCamera(!currentCamera);
        zapperCamera.current.start(currentCamera);
    }

    function canvasLoaded(state: RootState) {
        setRenderer(state.gl as any);
        setScene(state.scene as any);
        mediaRecorder.current = new MasuMediaRecorder(state.gl as any);
    }

    function openMediaSaveOverlay(props: MediaSaveOverlayProps) {
        setMediaOverlayData(prev => props);
        setMediaOverlayVisible(true);
    }

    function closeMediaSaveOverlay() {
        setMediaOverlayVisible(false);
    }

    function startVideoRecording() {
        if (mediaRecorder.current.loadStatus.loading) {
            return false;
        } else {
            mediaRecorder.current.StartRecording();
            return true;
        }
    }

    function stopVideoRecording() {
        return mediaRecorder.current.StopRecording();
    }

    useEffect(() => {
        fetch(HttpUrls.loadTrackers).then(async res => {
            setTrackers(await res.json());
        });

    }, []);

    return (
        <>
            <Toolbar switchCamera={toggleCamera}/>
            <BrowserCompatibility />
            <ZapparCanvas  gl={{ antialias: true }} onCreated={canvasLoaded}>
                {renderer && scene && <ZapparCamera ref={zapperCamera} renderer={renderer} scene={scene} />}
                <Suspense fallback={null}>
                    {trackers.map((x, i) => (
                        <ImageTracker
                            key={i}
                            onNotVisible={() => {
                                setVisibleState(false);
                                toggleVideo(false);
                            }}
                            onNewAnchor={(anchor) => console.log(`New anchor ${anchor.id}`)}
                            onVisible={() => {
                                setVisibleState(true);
                                toggleVideo(true);
                            }}
                            targetImage={x.tracker}
                            visible={visibleState}
                        >
                            <VideoPlane ref={trackerContent} content={x.content}/>
                        </ImageTracker>
                    ))}
                </Suspense>
                {/* Normal directional light */}
                <directionalLight position={[2.5, 8, 5]} intensity={1.5} castShadow />
            </ZapparCanvas>
            {!mediaOverlayVisible && renderer && <RecordButton renderer={renderer} mediaPreviewTriggered={openMediaSaveOverlay} camera={zapperCamera} startVideoRecording={startVideoRecording} stopVideoRecording={stopVideoRecording} />}
            {mediaOverlayVisible && <MediaSaveOverlay data={mediaOverlayData} closeOverlay={closeMediaSaveOverlay} />}
        </>
    );
}

const VideoPlane = forwardRef((props: {content: string}, ref) => {

    const videoRef = useRef<HTMLVideoElement>(document.createElement('video'));
    const textureRef = useRef<THREE.VideoTexture | null>(null);

    useEffect(() => {
        const video = videoRef.current;
        video.crossOrigin = 'Anonymous';
        video.src = props.content;
        video.loop = true;
        video.muted = true;
        video.controls = true;
        video.playsInline = true;

        const texture = new THREE.VideoTexture(video);
        textureRef.current = texture;
    }, []);

    useImperativeHandle(ref, () => ({
        playVideo() {
            const video = videoRef.current;
            video.play()
        },
        pauseVideo() {
            const video = videoRef.current;
            video.play()
        }
    }));

    return (
        <mesh>
            <planeBufferGeometry args={[4, 2.25]} />
            <meshBasicMaterial>
                {textureRef.current && (
                    <primitive attach="map" object={textureRef.current} />
                )}
            </meshBasicMaterial>
        </mesh>
    );
});

export default App;
