
import { useState } from "react";
import "./camera-ui-screen.scss";

export const Toolbar = (props: ToolbarProps) => {

    const [screenToggleIcon, setScreenToggleIcon] = useState("fullscreen");

    function toggleFullScreen() {
        if (!document.fullscreenElement) {
            const elem = document.documentElement as any;
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.webkitRequestFullscreen) {
                /* Safari */
                elem.webkitRequestFullscreen();
            } else if (elem.msRequestFullscreen) {
                /* IE11 */
                elem.msRequestFullscreen();
            }

            setScreenToggleIcon("fullscreen_exit");
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if ((document as any).webkitExitFullscreen) {
                /* Safari */
                (document as any).webkitExitFullscreen();
            } else if ((document as any).msExitFullscreen) {
                /* IE11 */
                (document as any).msExitFullscreen();
            }

            setScreenToggleIcon("fullscreen");
        }
    }

    return (
        <div className="toolbar">
            <div className="h-100 right-icons">
                <button id="camera-swap-button" className="no-bg-button h-100">
                    <span className="material-symbols-outlined" onClick={props.switchCamera}>cameraswitch</span>
                </button>
                <button id="full-screen-toggle-button" className="no-bg-button h-100" onClick={toggleFullScreen}>
                    <span className="material-symbols-outlined">{screenToggleIcon}</span>
                </button>
            </div>
        </div>
    )
}

type ToolbarProps = {
    switchCamera: () => void;
}