import { useEffect, useRef, useState } from "react";
import { Observable, Subject, filter, finalize, fromEvent, interval, map, merge, switchMap, takeUntil, tap, timer } from "rxjs";
import { WebGLRenderer } from "three";
import { MAX_VIDEO_DURATION } from "../../libs/media-recorder";
import { ZapparCameraHandles } from "../../libs/zappar-react-three-fiber";
import { MediaSaveOverlayProps } from "../media-save-overlay/media-save-overlay";
import "./camera-ui-screen.scss";

const holdDurationForVideo = 1000;
enum RecordButtonState {
    Idle = 1,
    Recording,
    Processing
}

export const RecordButton = (props: ExportButtonProps) => {
    const [buttonRecordingState, setButtonRecordingState] = useState(RecordButtonState.Idle);
    const [progressButtonStyle, setProgressButtonStyle] = useState(recordButtonStyle());
    const buttonState= useRef({
        recordButtonInterval: null,
    });

    function recordButtonStyle() {
        let precision = 64;
        let radius = 45;
        let c = [...Array(precision)].map((_, i) => {
          let a = (-i / (precision - 1)) * Math.PI * 2;
          let x = Math.cos(a) * radius + 50;
          let y = Math.sin(a) * radius + 50;
          return `${x}% ${y}%`;
        });

        return {
            clipPath: `polygon(100% 50%, 100% 100%, 0 100%, 0 0, 100% 0, 100% 50%, ${c.join(',')})`,
            background: ""
        };
    }


    const buttonRef = useRef(null);
    useEffect(() => {
      const button = buttonRef.current;
  
      if (button) {
        const mouseDown$ = fromEvent(button, 'mousedown');
        const mouseUp$ = fromEvent(document, 'mouseup');
        const mouseLeave$ = fromEvent(button, 'mouseleave');
        
        const touchStart$ = (fromEvent(button, 'touchstart') as Observable<TouchEvent>).pipe(tap(x => x.preventDefault()));
        const touchMove$ = (fromEvent(button, "touchmove") as Observable<any>).pipe(map(e => {
            const buttonLocation = e.target.getBoundingClientRect();
            const touchLocation = e.touches[0];

            const xAxisCollide = touchLocation.clientX >= buttonLocation.left && touchLocation.clientX <= buttonLocation.right;
            const yAxisCollide = touchLocation.clientY >= buttonLocation.top && touchLocation.clientY <= buttonLocation.bottom;
            if (xAxisCollide && yAxisCollide) {
                return {
                    e,
                    insideButton: true
                }
            } else {
                return {
                    e,
                    insideButton: false
                }
            }
        }))
        const touchEnd$ = fromEvent(document, 'touchend');
        const userCancelJesture$ = new Subject();
  
        let userCancelled = false;
        const start$ = merge(mouseDown$, touchStart$).pipe(
            tap(() => {
                // User can cancel the operation if swiped away before 1 second.
                userCancelled = false;
                merge(mouseLeave$, touchMove$.pipe(filter(x => !x.insideButton))).pipe(takeUntil(timer(holdDurationForVideo - 1)), takeUntil(userCancelJesture$), tap(x => {
                    userCancelled = true;
                    userCancelJesture$.next(true);
                })).subscribe()
            }),
            switchMap(() => {
                let videoRecordingStarted = false;
                return interval(holdDurationForVideo).pipe(
                    takeUntil(mouseUp$),
                    takeUntil(userCancelJesture$),
                    takeUntil(touchEnd$),
                    tap(x => {
                        if (!videoRecordingStarted) {
                            startVideoRecording();
                            videoRecordingStarted = true;
                        }
                    }),
                    finalize(() => {
                        if (userCancelled) {
                            return;
                        }

                        if (!videoRecordingStarted) {
                            capturePhoto();
                        } else {
                            stopVideoRecording();
                            videoRecordingStarted = false;
                        }
                    }),
                )
            })
        );

        const subscription = start$.subscribe();
        return () => {
          subscription.unsubscribe();
        };
      }
    }, []);

    function showVideoRecordView() {
        setButtonRecordingState(RecordButtonState.Recording);
        let i = 0;
        const interval = 100;
        const ref = setInterval(() => {
            setProgressButtonStyle(prevValue => ({
                ...prevValue,
                background: `conic-gradient(red ${i}deg, #bbbbbb 1deg)`
            }));
            i += (360 / (MAX_VIDEO_DURATION * (1000 / interval)));

            if (i >= 360) {
                // When max time is reached, we will stop the video recording ourself
                stopVideoRecording();
            }
        }, interval);
        buttonState.current.recordButtonInterval = ref;
    }
  
    async function startVideoRecording() {
        if (props.startVideoRecording()) {
            showVideoRecordView();
        } else {
            alert("Loading components.. please wait");
        }
    };
  
    function videoRecordingCompleted(res: Blob) {
        const url = URL.createObjectURL(res);
        const overlayData: MediaSaveOverlayProps = {
            mediaType: "video",
            src: url,
            blob: res,
            fileType: "video/mp4"
        }
        props.mediaPreviewTriggered(overlayData);
    };

    async function stopVideoRecording() {
        clearInterval(buttonState.current.recordButtonInterval);
        setButtonRecordingState(RecordButtonState.Processing);
        setProgressButtonStyle(prevValue => ({
            ...prevValue,
            background: ``
        }));
        const res = await props.stopVideoRecording();
        if (res instanceof Blob) {
            videoRecordingCompleted(res);
        } else {
            console.log(res);
        }
    }
  
    const capturePhoto = async () => {
        const mimeType = 'image/png';
        props.camera.current.rerender();
        const imagePreview = props.renderer.domElement.toDataURL(mimeType);
        const file = await new Promise<Blob>((resolve) => {
            props.renderer.domElement.toBlob(x => {
                resolve(x);
            });
        });

        const overlayData: MediaSaveOverlayProps = {
            mediaType: "img",
            src: imagePreview,
            blob: file,
            fileType: mimeType
        }
        props.mediaPreviewTriggered(overlayData);
    };

    const recordButtonClass = () => {
        switch(buttonRecordingState) {
            case RecordButtonState.Idle:
                return "";
            case RecordButtonState.Recording:
                return "recording";
            case RecordButtonState.Processing:
                return "processing";
            default:
                return ""
        }
    }

    return (
        <div className="recorders">
            <div className="button-container">
                <button id="progressButton" className={"progress-button " + recordButtonClass()}
                ref={buttonRef}>
                    <div style={progressButtonStyle} className="progress"></div>
                    <div className="circle"></div>
                </button>
            </div>
        </div>
    )
}

type ExportButtonProps = {
    renderer: WebGLRenderer;
    mediaPreviewTriggered: (a: MediaSaveOverlayProps) => void;
    camera: React.MutableRefObject<ZapparCameraHandles>;
    startVideoRecording: () => boolean;
    stopVideoRecording: () => Promise<Blob | string>;
}