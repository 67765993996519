import React, { useEffect, useLayoutEffect, useState } from "react";
import { Html } from "@react-three/drei";
const style = {
    position: "absolute",
    bottom: "5%",
    width: "200px",
    left: "calc(50% - 100px)",
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    color: "white",
    textAlign: "center",
    fontFamily: "sans-serif",
    padding: "10px",
    userSelect: "none",
    borderRadius: "5px",
};
/**
 * Renders a button which toggles the current placementMode.
 */
function PlacementUI({ onInteract, placementType }) {
    const [placementMode, setPlacementMode] = useState(true);
    const [ready, setReady] = useState(false);
    useLayoutEffect(() => {
        onInteract(placementMode);
    }, [placementMode]);
    useEffect(() => {
        setReady(true);
    }, []);
    if (!ready)
        return null;
    if (placementType === "placement-only" && !placementMode)
        return null;
    return (React.createElement(Html, { fullscreen: true, style: { left: "0px", top: "0px" } },
        React.createElement("div", { style: { width: "100%", height: "100%", position: "absolute", backgroundColor: "rgba(0, 0, 0, 0.1)" } },
            React.createElement("div", { id: "ZapparPlacementUIHelper", role: "button", style: style, tabIndex: 0, onKeyPress: () => {
                    setPlacementMode((currentPlacementMode) => !currentPlacementMode);
                }, onClick: () => {
                    setPlacementMode((currentPlacementMode) => !currentPlacementMode);
                } },
                "Tap here to",
                placementMode ? " place " : " pick up ",
                "the object"))));
}
export default PlacementUI;
