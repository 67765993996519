import * as ZapparThree from "@zappar/zappar-threejs";
import React, { forwardRef, useRef } from "react";
import { extend } from "@react-three/fiber";
import mergeRefs from "react-merge-refs";
import useStore from "../../store";
const { FaceLandmarkGroup } = ZapparThree;
extend({ FaceLandmarkGroup });
/**
 * A `THREE.Group` which attaches content to a known point (landmark) on a face as it moves around in the camera view.
 * Landmarks will remain accurate, even as the user's expression changes.
 * @see https://docs.zap.works/universal-ar/web-libraries/react-threejs/face-tracking/
 */
const zapparFaceLandmark = forwardRef((props, ref) => {
    var _a;
    const { children, trackerGroup, camera, target } = props;
    const faceLandmarkGroupRef = useRef();
    const store = {
        camera: useStore.camera((state) => state),
        faceTracker: useStore.faceTracker((state) => state),
    };
    const faceTracker = ((_a = trackerGroup === null || trackerGroup === void 0 ? void 0 : trackerGroup.current) === null || _a === void 0 ? void 0 : _a.faceTracker) ? trackerGroup.current.faceTracker : store.faceTracker.object;
    const zapparCamera = (camera === null || camera === void 0 ? void 0 : camera.current) ? camera.current : store.camera.object;
    if (!faceTracker || !zapparCamera)
        return null;
    return (React.createElement("faceLandmarkGroup", Object.assign({ args: [zapparCamera, faceTracker, ZapparThree.FaceLandmarkName[target.toUpperCase().replace("-", "_")]], ref: mergeRefs([faceLandmarkGroupRef, ref]) }, props), children));
});
export default zapparFaceLandmark;
