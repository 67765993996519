import * as ZapparThree from "@zappar/zappar-threejs";
import React, { forwardRef, useEffect, useState } from "react";
import mergeRefs from "react-merge-refs";
import { extend, useFrame } from "@react-three/fiber";
const { FaceBufferGeometry } = ZapparThree;
extend({ FaceBufferGeometry });
/**
 * A THREE.BufferGeometry that fits to the user's face and deforms as the user's expression changes.
 * @see https://docs.zap.works/universal-ar/web-libraries/react-threejs/face-tracking/
 */
const ZapparFaceBufferGeometry = forwardRef((props, ref) => {
    const { fillMouth = false, fillEyeLeft = false, fillEyeRight = false, fillNeck = false, fullHead = false, children, trackerGroup } = props;
    const faceBufferGeometryRef = React.useRef();
    const [faceMesh, setFaceMesh] = useState();
    useEffect(() => {
        if (fullHead) {
            setFaceMesh(new ZapparThree.FaceMeshLoader().loadFullHeadSimplified({
                fillMouth,
                fillEyeLeft,
                fillEyeRight,
                fillNeck,
            }));
        }
        else {
            setFaceMesh(new ZapparThree.FaceMeshLoader().loadFace({
                fillMouth,
                fillEyeLeft,
                fillEyeRight,
            }));
        }
    }, [fillMouth, fillEyeLeft, fillEyeRight, fillNeck]);
    // TODO: Handle multiple anchors
    useFrame(() => {
        if (faceBufferGeometryRef.current && trackerGroup && trackerGroup.current) {
            faceBufferGeometryRef.current.updateFromFaceAnchorGroup(trackerGroup.current);
        }
    });
    if (!faceMesh)
        return null;
    return (React.createElement("faceBufferGeometry", Object.assign({ args: [faceMesh], ref: mergeRefs([faceBufferGeometryRef, ref]) }, props), children));
});
export default ZapparFaceBufferGeometry;
