import { FFmpeg } from "@ffmpeg/ffmpeg";
import { Subject, firstValueFrom } from "rxjs";
import { WebGLRenderer } from "three";
import { HttpUrls } from "../shared/constants/http-urls";
import { IsIOS } from "./common-functions";

export const MAX_VIDEO_DURATION = 35;

const videoFPS = 24;
const mp4Mime = "video/mp4";
const webmMime = (format: "SIMPLE" | "FORH264" | "FORVP8" = "SIMPLE") => {
    switch (format) {
        case "SIMPLE":
            return "video/webm";
        case "FORH264":
            return "video/webm;codecs=h264";
        case "FORVP8":
            return "video/webm; codecs=vp8";
        default:
            throw new Error("Invalid parameter for MIME selection");
    }
}

export class MasuMediaRecorder {

    // Only IOS supports native mp4 recording currently

    public loadStatus: {
        loading: boolean,
        complete: Promise<boolean>,
    }

    public StartRecording() {
        this._mediaRecorder.start();
    }

    public async StopRecording() {
        this._mediaRecorder.stop();
        return firstValueFrom(this._recordingSessionEnded$);
    }

    constructor(renderer: WebGLRenderer) {
        this.loadStatus = {
            loading: true,
            complete: new Promise<boolean>(resolve => {
                this._loadStatusInformer = resolve;
            })
        };
        this._renderer = renderer;
        this._createMediaRecorder();

        this.handleRecorderData = this.handleRecorderData.bind(this);
        this.mediaRecordingStopped = this.mediaRecordingStopped.bind(this);
        this.mediaRecorderError = this.mediaRecorderError.bind(this);
    }

    private _ffmpeg: FFmpeg;
    private _recordedBlobs: Blob[] = [];
    private _renderer: WebGLRenderer;
    private _mediaRecorder: MediaRecorder;
    private _h264CodecSupported: boolean;
    private _loadStatusInformer: (value: boolean | PromiseLike<boolean>) => void;
    private _recordingSessionEnded$ = new Subject<Blob | string>();

    private get _canvas() {
        return this._renderer.domElement;
    };

    private async _createMediaRecorder() {
        const mimeType = await (async () => {
            if (IsIOS()) {
                this._h264CodecSupported = true;
                this.loadingCompleted(true);
                return mp4Mime;
            } else {
                await this.testH264Codec();
                if (this._h264CodecSupported) {
                    this._ffmpeg = new FFmpeg();
                    await this._ffmpeg.load();
                    this.loadingCompleted(true);
                    return webmMime("FORH264");
                } else {
                    this.loadingCompleted(true);
                    return webmMime("FORVP8");
                }
            }
        })();
        this._mediaRecorder = new MediaRecorder(this._canvas.captureStream(videoFPS), {
            mimeType,
        });
        this._mediaRecorder.ondataavailable = this.handleRecorderData;
        this._mediaRecorder.onerror = this.mediaRecorderError;
        this._mediaRecorder.onstop = this.mediaRecordingStopped;
    }

    private async handleRecorderData(res: BlobEvent) {
        this._recordedBlobs.push(res.data);
    }

    private async mediaRecordingStopped() {
        let returnBlob: Blob;
        if (IsIOS()) {
            returnBlob = new Blob(this._recordedBlobs, {type: mp4Mime});
        } else if (this._h264CodecSupported) {
            const webmVideoBlob = new Blob(this._recordedBlobs, {type: webmMime()});
            await this._ffmpeg.writeFile("video.webm", new Uint8Array(await webmVideoBlob.arrayBuffer()));
            await this._ffmpeg.exec(['-encoders']);
            await this._ffmpeg.exec(['-i', 'video.webm', '-vcodec', 'copy', 'video.mp4']);
            const resultData = await this._ffmpeg.readFile("video.mp4");
            returnBlob = new Blob([resultData], {type: mp4Mime});

            // Clean space
            this._ffmpeg.deleteFile("video.webm");
            this._ffmpeg.deleteFile("video.mp4");
        } else {
            const blob = new Blob(this._recordedBlobs, {type: mp4Mime});
            const formData = new FormData();
            formData.append('video', blob);
            const result = await (await fetch(HttpUrls.convertWebmToMp4, {
                method: 'POST',
                body: formData
            })).json();

            if (!result || !result.success) {
                this._recordingSessionEnded$.next("Conversion api failed");
                return;
            }

            returnBlob = await (await fetch(result.fileUrl)).blob();
        }

        this._recordedBlobs = [];
        this._recordingSessionEnded$.next(returnBlob);
    }

    private mediaRecorderError(x) {
        console.error(x);
        this._recordingSessionEnded$.next(x);
    }

    private async testH264Codec() {
        const h264TestCodec = webmMime("FORH264");
        const testMediaRecorder = new MediaRecorder(this._canvas.captureStream(videoFPS), {
            mimeType: h264TestCodec
        });
        return new Promise<boolean>((resolve) => {
            const timer = setTimeout(() => {
                this._h264CodecSupported = true;
                resolve(true);
            }, 1000)
            testMediaRecorder.onerror = () => {
                clearTimeout(timer);
                this._h264CodecSupported = false;
                resolve(false);
            }
            testMediaRecorder.start();
        })
    }

    private loadingCompleted(status: boolean) {
        if (status) {
            this._loadStatusInformer(true);
            this.loadStatus.loading = false;
        }
    }
}