import * as ZapparThree from "@zappar/zappar-threejs";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import { extend } from "@react-three/fiber";
import mergeRefs from "react-merge-refs";
import useStore from "../../store";
import ToggleTrackerEnabledState from "../../hooks/trackerEnabled";
const { ImageAnchorGroup } = ZapparThree;
extend({ ImageAnchorGroup });
/**
 * A THREE.Group that attaches content to a known image as it moves around in the camera view.
 * @see https://docs.zap.works/universal-ar/web-libraries/react-threejs/image-tracking/
 */
const ZapparImageTracker = forwardRef((props, ref) => {
    const { useImageTracker, onNotVisible, onNewAnchor, onVisible, targetImage, camera, children, pipeline, enabled } = props;
    const imageTrackerGroupRef = useRef();
    const store = useStore.camera((state) => state);
    const zapparCamera = (camera === null || camera === void 0 ? void 0 : camera.current) ? camera.current : store.object;
    const [imageTracker, setImageTracker] = useState();
    useEffect(() => {
        setImageTracker(new ZapparThree.ImageTracker(targetImage, pipeline));
    }, [targetImage, pipeline]);
    useEffect(() => {
        if (imageTracker) {
            if (useImageTracker)
                useImageTracker(imageTracker);
            if (onNotVisible)
                imageTracker.onNotVisible.bind(onNotVisible);
            if (onNewAnchor)
                imageTracker.onNewAnchor.bind(onNewAnchor);
            if (onVisible)
                imageTracker.onVisible.bind(onVisible);
        }
        return () => {
            if (onNotVisible)
                imageTracker === null || imageTracker === void 0 ? void 0 : imageTracker.onNotVisible.unbind(onNotVisible);
            if (onNewAnchor)
                imageTracker === null || imageTracker === void 0 ? void 0 : imageTracker.onNewAnchor.unbind(onNewAnchor);
            if (onVisible)
                imageTracker === null || imageTracker === void 0 ? void 0 : imageTracker.onVisible.unbind(onVisible);
        };
    }, [useImageTracker, onVisible, onNewAnchor, onNotVisible, imageTracker]);
    ToggleTrackerEnabledState(imageTracker, enabled);
    if (!zapparCamera || !imageTracker)
        return null;
    return (React.createElement("imageAnchorGroup", Object.assign({ ref: mergeRefs([imageTrackerGroupRef, ref]), args: [zapparCamera, imageTracker] }, props), children));
});
export default ZapparImageTracker;
