import React from "react";
import { Canvas } from "@react-three/fiber";
// eslint-disable-next-line import/no-extraneous-dependencies
import { ResizeObserver } from "@juggle/resize-observer";
/**
 * A canvas with linear encoding, auto-updating dpr as well as tone mapping disabled.
 */
const zapparCanvas = (props) => {
    const { children } = props;
    return (React.createElement(Canvas, Object.assign({ flat: true, resize: { polyfill: ResizeObserver }, linear: true, dpr: window.devicePixelRatio, id: "zapparCanvas" }, props, { gl: Object.assign({ antialias: false }, props.gl) }), children));
};
export default zapparCanvas;
