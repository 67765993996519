import create from "zustand";
const camera = create((set) => ({
    object: undefined,
    set: (camera) => set({ object: camera }),
}));
const imageTracker = create((set) => ({
    object: undefined,
    set: (tracker) => set({ object: tracker }),
}));
const instantTracker = create((set) => ({
    object: undefined,
    set: (tracker) => set({ object: tracker }),
}));
const faceTracker = create((set) => ({
    object: undefined,
    set: (tracker) => set({ object: tracker }),
}));
// const cameraEnvironmentMap = create<Store.cameraEnvironmentMap>((set) => ({
//   object: undefined,
//   set: (tracker) => set({ object: tracker }),
// }));
export default {
    camera,
    imageTracker,
    instantTracker,
    faceTracker,
    // cameraEnvironmentMap,
};
