import {
    BrowserCompatibility as BrowCompat,
    ImageTracker as ImTracker,
    ZapparCamera as ZapCam
} from '@zappar/zappar-react-three-fiber';
import * as ZapparThree from "@zappar/zappar-threejs";
import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { Scene, WebGLRenderer } from 'three';

export function BrowserCompatibility() {
    return (
        <>
            {/* @ts-expect-error Server Component */}
            <BrowCompat />
        </>
    )
}

export const ZapparCamera = forwardRef<ZapparCameraHandles, ZapparCameraProps>((props, ref) => {

    const zapCamInstance = useRef<ZapparThree.Camera>()

    useImperativeHandle(ref, () => ({
        start(currentCamera: boolean) {
            zapCamInstance.current.start(currentCamera);
        },
        updateFrame() {
            zapCamInstance.current.updateFrame(props.renderer as any)
        },
        rerender() {
            props.renderer.render(props.scene, zapCamInstance.current);
        }
    }));

    return (
        <>
            {/* @ts-expect-error Server Component */}
            <ZapCam ref={zapCamInstance} />
        </>
    )
})

export const ImageTracker: React.FC<ImageTrackerProps> = (props: ImageTrackerProps) => {
    return (
        <>
            {/* @ts-expect-error Server Component */}
            <ImTracker
                onNotVisible={props.onNotVisible}
                onNewAnchor={props.onNewAnchor}
                onVisible={props.onVisible}
                targetImage={props.targetImage}
                camera={props.camera}
                useImageTracker={props.useImageTracker}
                animations={props.animations}
                pipeline={props.pipeline}
                enabled={props.enabled}
                visible={props.visible}
            >
                {props.children}
            </ImTracker>
        </>
    );
};

type ImageTrackerProps = {
    children?: React.ReactNode[] | React.ReactNode;
    onVisible?: ((anchor: ZapparThree.ImageAnchor) => void) | undefined;
    onNotVisible?: ((anchor: ZapparThree.ImageAnchor) => void) | undefined;
    onNewAnchor?: ((anchor: ZapparThree.ImageAnchor) => void) | undefined;
    targetImage: string;
    camera?: React.MutableRefObject<ZapparThree.Camera | undefined> | undefined;
    useImageTracker?: ((imageTracker: ZapparThree.ImageTracker) => void) | undefined;
    animations?: any;
    pipeline?: ZapparThree.Pipeline | undefined;
    enabled?: boolean | undefined;
    visible?: boolean | undefined;
}

type ZapparCameraProps = {
    renderer?: WebGLRenderer;
    scene: Scene;
}

export type ZapparCameraHandles = {
    start?: (currentCamera: boolean) => void;
    updateFrame?: () => void;
    rerender?: () => void;
}