/* eslint-disable no-param-reassign */
import { useEffect } from "react";
const ToggleTrackerEnabledState = (tracker, enabled) => {
    useEffect(() => {
        if (tracker && enabled !== undefined) {
            tracker.enabled = enabled;
        }
    }, [tracker, enabled]);
};
export default ToggleTrackerEnabledState;
