import { useEffect } from "react";
import * as ZapparThree from "@zappar/zappar-threejs";
/**
 * Creates a THREE.DefaultLoadingManager which is applied to all assets that can be loaded.
 * @see https://docs.zap.works/universal-ar/web-libraries/threejs/loading-manager/
 */
function ZapparLoader(props) {
    const { style, onLoad } = props;
    useEffect(() => {
        const loader = new ZapparThree.DefaultLoaderUI({
            style: style,
            onLoad,
        });
        return () => loader.dispose();
    }, [props]);
    return null;
}
export default ZapparLoader;
