import { IsIOS } from "../../libs/common-functions";
import "./media-save-overlay.scss";

export const MediaSaveOverlay = (props: {data: MediaSaveOverlayProps, closeOverlay: () => void}) => {

    function closeOverlay() {
        props.closeOverlay();
    }

    function download() {
        const fileType = props.data.fileType;
        const extension = fileType.split("/")[1];
        const fileName = fileType.includes("video") ? `recording_1.${extension}` : `image_1.${extension}`;
        const downloadFile = new File([props.data.blob], fileName, {
            lastModified: new Date().getTime(),
            type: fileType
        });

        const url = window.URL.createObjectURL(downloadFile);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
    }

    async function share() {
        const fileType = props.data.fileType;
        const extension = fileType.split("/")[1];

        const fileName = fileType.includes("video") ? `recording_1.${extension}` : `image_1.${extension}`;
        const shareFile = new File([props.data.blob], fileName, {
            lastModified: new Date().getTime(),
            type: fileType
        })

        if (!navigator.canShare || !navigator.canShare({ files: [shareFile] })) {
            alert('File sharing not supported on this browser.');
            return;
        }

        try {
            await navigator.share({
                files: [shareFile],
                title: 'Share Media',
                text: "A rare footage from the Masuverse"
            });
            console.log('Image shared successfully');
        } catch (err) {
           console.error('Error sharing the image:', err);
        }
    }

    return (
        <div id="overlay" className="overlay">
            <div className="overlay-container">
                <button className="no-bg-button" id="closeOverlay" onClick={closeOverlay}>
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#FFFFFF"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" /></svg>
                </button>
                <div id="previewContainer">
                    {props.data.mediaType === "video" && <VideoTag src={props.data.src} />}
                    {props.data.mediaType === "img" && <ImageTag src={props.data.src} />}
                </div>
                <button className="overlay-action-button" id="shareButton" onClick={share}>
                    <span className="material-symbols-outlined">{IsIOS() ? 'ios_share' : 'share'}</span>
                    Save & Share
                </button>
                <button className="overlay-action-button" id="downloadButton" onClick={download}>
                    <span className="material-symbols-outlined">download</span>
                    Download
                </button>
            </div>
        </div>
    )
}

const VideoTag = (props: MediaSaveOverlayProps) => {
    return (
        <video src={props.src} autoPlay playsInline controls></video>
    )
}

const ImageTag = (props: MediaSaveOverlayProps) => {
    return (
        <img src={props.src} alt="Camera snapshot" />
    )
}

export type MediaSaveOverlayProps = {
    mediaType?: "img" | "video";
    src?: string;
    blob?: Blob;
    fileType?: string;
}